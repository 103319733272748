@import url("https://fonts.googleapis.com/css2?family=Special+Elite&family=Inconsolata:wght@200..900&&display=swap");
:root {
  font-family: Orbitron, monospace;
  --black: #010409;
  --dark: hsl(216, 28%, 7%);
  --gray: hsl(216, 28%, 10%);
  --main: #eff6ff;
  /* --main: snow; */
  --light: #eff6ff;
  --highlight: hsl(213, 94%, 68%);
  --highlight: hsl(48, 100%, 40%);
  --highlight-alt: #1e3a8a;
  --highlight-alt: hsl(48, 100%, 10%);
  --min-h1-font-size: 2.2rem; /* Define minimum font size in h1 units */
  --max-h1-font-size: 10rem; /* Define maximum font size in h1 units */
}

html,
body {
  margin: 0;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  color: var(--main);
  overflow-x: hidden;
  width: 100vw;
}

#app {
  flex: 1;
  background: var(--black);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  font-size: clamp(
    var(--min-h1-font-size),
    6.5vw,
    var(--max-h1-font-size)
  ); /* Responsive font size from min */
  color: var(--light);
  margin: 0;
}

h2 {
  font-size: 5rem;
}

a {
  color: var(--highlight);
}
a:hover,
a:focus,
a:active {
  filter: brightness(1.2);
}

#waveform {
  background-image: linear-gradient(0deg, transparent, var(--dark), transparent);
  width: 100% !important;
  margin: 50px 0;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

pre {
  white-space: pre-wrap;
  line-height: 1.5rem;
  letter-spacing: 4px;
  color: var(--light);
}

blockquote {
  letter-spacing: 4px;
  font-size: 2rem;
  padding: 0;
  margin: 0;
  padding-top: 1rem;
  color: var(--highlight);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-top: solid 3px transparent;
  width: fit-content;
  border-top: solid 3px var(--highlight);
}

.highlight {
  color: var(--highlight);
  margin-top: 1.5rem;
}

.spacer {
  flex: 1;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}

.samples {
  flex: 1;
  width: 100%;
  display: grid;
  max-width: 1440px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.samples button {
  background-color: transparent;
  border: solid thin var(--highlight-alt);
  padding: 0.75rem 2rem;
  color: var(--light);
  font-family: inherit;
  cursor: pointer;
  transition: backgroundColor 0.2s ease-in-out;
  width: 100%;
  text-transform: uppercase;
}
.samples button:hover {
  background-color: var(--dark);
  outline: solid 2px var(--highlight-alt);
}
.samples button.active {
  background-color: var(--gray);
  outline: solid 2px var(--highlight-alt);
}
@media screen and (min-width: 768px) {
  .flex {
    flex-direction: row;
  }
  .samples button {
    width: auto;
  }
}

#text {
  padding: 1rem;
  line-height: 1.5rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: Inconsolata, monospace;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.4rem;
}

footer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
  justify-content: center;
  font-size: 0.8rem;
  padding-left: 2rem;
  background: var(--black);
}

@media screen and (min-width: 1600px) {
  #text {
    max-width: 600px;
    top: 0;
    right: 0;
    position: absolute;
    background: hsl(217.5deg 80% 1.96% / 80%);
    height: 65vh;
    display: flex;
    line-height: 1.5rem;
    font-size: 1.2rem;
    z-index: 10;
    overflow: hidden;
    align-items: center;
  }
  #text > div {
    margin: 2rem 0;
    height: calc(100% - 4rem);
    overflow: auto;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    pointer-events: all;
  }
}

::-webkit-scrollbar {
  width: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--highlight);
}

#sticky {
  display: none;
  position: fixed;
  padding: 0 0 1rem 2rem;
  top: 0;
  width: calc(100% - 2rem);
  background: var(--black);
  z-index: 100;
}
.pause {
  display: none;
}

.playbtn {
  margin-top: 1rem;
  align-self: flex-start;
  height: 32px;
  display: none;
  cursor: pointer;
}
.playbtn:hover {
  filter: opacity(0.8);
}
.playbtn.active {
  display: block;
}
