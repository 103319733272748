@import "https://fonts.googleapis.com/css2?family=Special+Elite&family=Inconsolata:wght@200..900&&display=swap";
:root {
  --black: #010409;
  --dark: #0d1117;
  --gray: #121821;
  --main: #eff6ff;
  --light: #eff6ff;
  --highlight: #61a6fa;
  --highlight: #cca300;
  --highlight-alt: #1e3a8a;
  --highlight-alt: #332900;
  --min-h1-font-size: 2.2rem;
  --max-h1-font-size: 10rem;
  font-family: Orbitron, monospace;
}

html, body {
  min-height: 100%;
  margin: 0;
}

body {
  color: var(--main);
  flex-direction: column;
  width: 100vw;
  display: flex;
  overflow-x: hidden;
}

#app {
  background: var(--black);
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  display: flex;
  overflow: hidden;
}

h1 {
  font-size: clamp(var(--min-h1-font-size), 6.5vw, var(--max-h1-font-size));
  color: var(--light);
  margin: 0;
}

h2 {
  font-size: 5rem;
}

a {
  color: var(--highlight);
}

a:hover, a:focus, a:active {
  filter: brightness(1.2);
}

#waveform {
  background-image: linear-gradient(0deg, transparent, var(--dark), transparent);
  margin: 50px 0;
  width: 100% !important;
}

.container {
  flex-direction: column;
  padding: 2rem;
  display: flex;
}

pre {
  white-space: pre-wrap;
  letter-spacing: 4px;
  color: var(--light);
  line-height: 1.5rem;
}

blockquote {
  letter-spacing: 4px;
  color: var(--highlight);
  cursor: pointer;
  border-top: 3px solid #0000;
  border-top: solid 3px var(--highlight);
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 1rem 0 0;
  font-size: 2rem;
  transition: all .2s ease-in-out;
}

.highlight {
  color: var(--highlight);
  margin-top: 1.5rem;
}

.spacer {
  flex: 1;
}

.flex {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.samples {
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  width: 100%;
  max-width: 1440px;
  display: grid;
}

.samples button {
  border: solid thin var(--highlight-alt);
  color: var(--light);
  cursor: pointer;
  text-transform: uppercase;
  background-color: #0000;
  width: 100%;
  padding: .75rem 2rem;
  font-family: inherit;
  transition: backgroundColor .2s ease-in-out;
}

.samples button:hover {
  background-color: var(--dark);
  outline: solid 2px var(--highlight-alt);
}

.samples button.active {
  background-color: var(--gray);
  outline: solid 2px var(--highlight-alt);
}

@media screen and (width >= 768px) {
  .flex {
    flex-direction: row;
  }

  .samples button {
    width: auto;
  }
}

#text {
  padding: 1rem;
  font-family: Inconsolata, monospace;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 1.4rem;
}

footer {
  background: var(--black);
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 2rem);
  padding-left: 2rem;
  font-size: .8rem;
  display: flex;
}

@media screen and (width >= 1600px) {
  #text {
    z-index: 10;
    background: #010409cc;
    align-items: center;
    max-width: 600px;
    height: 65vh;
    font-size: 1.2rem;
    line-height: 1.5rem;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
  }

  #text > div {
    pointer-events: all;
    flex-direction: column;
    height: calc(100% - 4rem);
    max-height: 75vh;
    margin: 2rem 0;
    display: flex;
    overflow: auto;
  }
}

::-webkit-scrollbar {
  width: .25rem;
}

::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--highlight);
}

#sticky {
  background: var(--black);
  z-index: 100;
  width: calc(100% - 2rem);
  padding: 0 0 1rem 2rem;
  display: none;
  position: fixed;
  top: 0;
}

.pause {
  display: none;
}

.playbtn {
  cursor: pointer;
  align-self: flex-start;
  height: 32px;
  margin-top: 1rem;
  display: none;
}

.playbtn:hover {
  filter: opacity(.8);
}

.playbtn.active {
  display: block;
}

/*# sourceMappingURL=index.8a5458c2.css.map */
